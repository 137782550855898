import React from 'react'
import Layout from '../components/layout'
import mv01 from '../assets/images/movie/mv01.jpg'
import mvsp from '../assets/images/movie/mv_sp.jpg'
import Helmet from 'react-helmet'

import { Link } from 'gatsby'

const MV = () => {
  const siteTitle = 'Onodera Ryo | mv'

  return(
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
      </Helmet>
      <div id="main">
        <h2 class="name2">Music Video</h2>
        <p class="own_desc2">phai - I'll do (feat.POINT HOPE)<br></br>Director / Producer </p>
        <a class="own_desc2" href="https://youtu.be/q_UVKz1P2CI">https://youtu.be/q_UVKz1P2CI</a>
        <img src={mv01} alt="mv" class="commercial_img pc" />
        <img src={mvsp} alt="mv" class="commercial_img sp" />
      </div>
    </Layout>
  )
}

export default MV